var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      staticClass: "basicButton",
      class: [_vm.styles, { disabled: _vm.disabled }],
      attrs: { type: "text" },
      on: { click: _vm.clicked },
    },
    [_vm._v(" " + _vm._s(_vm.title) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }