<template>
  <el-button
    class="basicButton"
    type="text"
    :class="[styles, { disabled: disabled }]"
    @click="clicked"
  >
    {{ title }}
  </el-button>
</template>

<script>
export default {
  name: 'BasicButton',
  props: {
    styles: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clicked() {
      if (!this.disabled) {
        this.$emit('button-clicked')
      }
    },
  },
}
</script>

<style lang="sass">
.el-button.basicButton.el-button--text
  border: none
  background-color: inherit
  font-size: 14px
  color: $greyText
  &.primary span
    color: $color-blue-light
</style>
